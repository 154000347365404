import React from "react";
import {
  Button,
  Form,
  Row,
  Col,
  InputGroup,
  FloatingLabel,
  Toast,
} from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { useState, useEffect } from "react";

export function StudentForm() {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);
  };

  const toggleChildProp = () => {};

  return (
    <div style={{ padding: "30px" }}>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="mb-1">
          <Form.Group
            className="mb-3"
            as={Col}
            md="6"
            controlId="validationCustom01"
          >
            <Form.Label>Full name</Form.Label>
            <Form.Control required type="text" placeholder="Jesse Gallagar" />
            <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            className="mb-3"
            as={Col}
            md="6"
            controlId="validationCustomUsername"
          >
            <Form.Label>Email Address</Form.Label>
            <InputGroup hasValidation>
              <InputGroup.Text id="inputGroupPrepend">@</InputGroup.Text>
              <Form.Control
                type="email"
                placeholder="Email Address"
                aria-describedby="inputGroupPrepend"
                required
              />
              <Form.Control.Feedback type="invalid">
                Please enter a valid E-mail.
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Row>
        <Row className="mb-1">
          <Form.Group className="mb-3" as={Col} controlId="validationCustom04">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control type="number" placeholder="Contact Number" required />
            <Form.Control.Feedback type="invalid">
              Please provide a phone number.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
            as={Col}
            md="3"
            controlId="validationCustom04"
          >
            <Form.Label>City</Form.Label>
            <Form.Control type="text" placeholder="City" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid City.
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group
            className="mb-3"
            as={Col}
            md="3"
            controlId="validationCustom05"
          >
            <Form.Label>Year</Form.Label>
            <Form.Control type="text" placeholder="Year" required />
            <Form.Control.Feedback type="invalid">
              Please provide a valid year.
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="mb-1">
          <Form.Group
            className="mb-3"
            as={Col}
            md="6"
            controlId="validationCustom03"
          >
            <FloatingLabel
              controlId="floatingTextarea2"
              label="Your query here"
              placeholder="Your query here"
            >
              <Form.Control as="textarea" style={{ height: "80px" }} />
            </FloatingLabel>
            <Form.Control.Feedback type="invalid">
              You forgot to write how we can help you !
            </Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Form.Group className="mb-3">
          <Form.Check
            required
            label="Agree to terms and conditions"
            feedback="You must agree before submitting."
          />
        </Form.Group>
        <Button size="sm" variant="outline-success" type="submit">
          Send Query
        </Button>
      </Form>
    </div>
  );
}
export function EmployerForm({ onChange }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    university: "",
    phone: "",
    major: "",
  });
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Send email using Email.js
    try {
      await emailjs.send(
        "service_4wqu5ra",
        "template_wg2mlk9",
        formData,
        "KWQ1Ygn6C9Mm3PY3i"
      );

      console.log("Email sent successfully");

      // Call the parent component function to indicate form submission
      onChange(true);
    } catch (error) {
      console.error("Email error:", error);
    }
  };
  return (
    <div style={{ padding: "30px" }}>
      <div>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>First Name:</Form.Label>
            <Form.Control
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Last Name:</Form.Label>
            <Form.Control
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Email:</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>University:</Form.Label>
            <Form.Control
              type="text"
              name="university"
              value={formData.university}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Phone number</Form.Label>
            <Form.Control
              type="number"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Major:</Form.Label>
            <Form.Control
              type="text"
              name="major"
              value={formData.major}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <br />
          <Button className="ctaButton" type="submit">
            Submit Application
          </Button>
        </Form>
      </div>
    </div>
  );
}
