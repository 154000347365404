import React from "react";
import workIP from "../assets/working.png";
import { Button, ListGroup, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Fade from "react-reveal/Fade";

export default function NotFound() {
  const history = useHistory();
  return (
    <>
      <div
        className="CustomCard "
        style={{
          display: "block",
          color: "#fff",
          margin: "auto",
          padding: 100,
        }}
      >
        <h1 className="headingFont"> Looks like this page is in progress ! </h1>
        <Fade bottom>
          <img
            src={workIP}
            alt="speed"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              marginTop: "15px",
              borderRadius: "8px",
            }}
          />
        </Fade>

        <br />
        <Button
          onClick={() => history.push("/")}
          variant="light"
          className="ctaButton"
        >
          Go To Home
        </Button>
      </div>
    </>
  );
}
