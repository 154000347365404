import {
  Button,
  Row,
  Col,
  ListGroup,
  Toast,
  ToastContainer,
} from "react-bootstrap";
import { React, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Fade from "react-reveal/Slide";
import kb from "../assets/KiloBit.png";
import mb from "../assets/MegaBit.png";
import gb from "../assets/GigaBit.png";
import tb from "../assets/TeraBit.png";
import faq from "../assets/suitcase.png";
import tik from "../assets/arrow.gif";
import { EmployerForm } from "./StudentForm";
import frontEnd from "../assets/frontEnd.png";
export default function Careers() {
  const [show, setShow] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const handleClose = () => setShow(!show);

  const handleChildPropChange = (newValue) => {
    setShow(false);
    setShowToast(true);
  };

  const roleContentFullStack = [
    "Collaborating with experienced developers to design and implement user-friendly web applications.",
    "Working on diverse projects, gaining experience with various technologies and frameworks.",
    "Participating in code reviews, learning from feedback, and enhancing your coding proficiency.",
    "Contributing to the creation of responsive and scalable software solutions.",
  ];

  const offerContentFullStack = [
    "Mentorship from seasoned professionals, guiding you in your journey.",
    "A supportive and inclusive work culture that values creativity and teamwork.",
    "Opportunities to attend workshops and tech-related events to expand your knowledge.",
    "A chance to learn from real-world challenges and build a strong foundation for your future career.",
  ];

  const lookingForContentFullStack = [
    "A solid foundation in programming languages and a keen interest in web development.",
    "A hunger to learn and explore new technologies, embracing challenges with enthusiasm.",
    "Strong problem-solving skills and a drive to excel in the software development domain.",
    "Effective communication skills and a collaborative spirit.",
    "A passion for innovation and a desire to contribute meaningfully to projects.",
  ];

  const roleContent = [
    "Collaborate with experienced marketing professionals to develop and implement effective marketing strategies.",
    "Work on diverse projects that enhance your skills in various areas of marketing, including social media, content creation, and market research.",
    "Participate in brainstorming sessions and contribute innovative ideas to marketing campaigns.",
    "Learn from feedback and refine your marketing skills through practical experience.",
    "Assist in organizing and coordinating events and promotional activities.",
    "Gain insights into customer behavior and preferences through data analysis.",
    "Contribute to the creation of engaging and compelling marketing materials.",
  ];
  const offerContent = [
    "Receive mentorship from seasoned marketing professionals dedicated to your growth and development.",
    "Enjoy a supportive and inclusive work culture that values creativity, teamwork, and collaboration.",
    "Seize opportunities to attend workshops, seminars, and industry-related events to expand your marketing knowledge.",
    "Face real-world marketing challenges, providing a strong foundation for your future career.",
  ];
  const lookingForContent = [
    "Demonstrate a strong interest in marketing and a willingness to learn and adapt in a fast-paced environment.",
    "Possess excellent communication skills, both written and verbal.",
    "Showcase creative thinking and the ability to generate innovative marketing ideas.",
    "Exhibit strong organizational skills and attention to detail.",
    "Display enthusiasm for problem-solving and a proactive attitude.",
    "Embrace a collaborative mindset and the ability to work effectively within a team.",
  ];

  const renderListItems = (contentArray) => {
    return contentArray.map((item, index) => (
      <ListGroup.Item key={index}>
        <span>
          <img alt="icon" src={tik} className="mmTik" />
        </span>
        {item}
      </ListGroup.Item>
    ));
  };

  return (
    <div>
      <div className="workshopsBody">
        <ToastContainer
          bg="success"
          position="bottom-start"
          className="p-3"
          style={{ zIndex: 1 }}
        >
          <Toast
            onClose={() => setShowToast(false)}
            bg="success"
            show={showToast}
            delay={5000}
            autohide
          >
            <Toast.Header>
              <strong className="me-auto">Submitted ! </strong>
              <small>Marketting Intern</small>
            </Toast.Header>
            <Toast.Body>
              We have got your application ! We will send you an email and
              contact you with next step.
            </Toast.Body>
          </Toast>
        </ToastContainer>
        <div className="cardStyles fullWidthHomeCard">
          <Row>
            <Col sm={8}>
              <Fade left>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <h1 className="headingFont">
                    Interested in Joining The RichLabs Team?
                  </h1>
                  <p className="mainSubHeading">
                    RichLabs is always looking for talented software
                    professionals to share in our growth.
                  </p>
                </div>
              </Fade>
            </Col>
            <Col sm={4}>
              <Fade right>
                <div
                  style={{
                    display: "block",
                    margin: "auto",
                    maxWidth: "300px",
                    paddingTop: "10px",
                  }}
                >
                  <img src={faq} alt="speed" className="CardImages" />
                </div>
              </Fade>
            </Col>
          </Row>
        </div>
        <div className="fourCardRow">
          <Fade bottom>
            <div className="fourCardStyle">
              <img
                style={{ alignSelf: "center", maxWidth: "60%" }}
                src={kb}
                alt="speed"
              />
              <h4 className="subsCardHeading">Great Salary</h4>
              <p className="headingSubtext">
                Unlock Your Earning Potential: Our Competitive Salary Package
                Rewards your Expertise and Commitment, Providing Financial
                Security and Recognition for your Hard Work.
              </p>
            </div>
          </Fade>
          <Fade bottom>
            <div className="fourCardStyle cardFixedHeight">
              <img
                style={{ alignSelf: "center", maxWidth: "60%" }}
                src={mb}
                alt="speed"
              />
              <h4 className="subsCardHeading"> Flexible Working hours</h4>
              <p>
                Work-Life Synergy: Embrace Work-Life Balance with Flexible
                Hours, Empowering You to Achieve Optimal Productivity while
                Prioritizing Personal Time and Responsibilities.
              </p>
            </div>
          </Fade>
          <Fade bottom>
            <div className="fourCardStyle cardFixedHeight">
              <img
                style={{ alignSelf: "center", maxWidth: "60%" }}
                src={gb}
                alt="speed"
              />
              <h4 className="subsCardHeading">Health benefits</h4>
              <p>
                Your Well-Being is Paramount: We Prioritize Your Health and
                Peace of Mind with Comprehensive Health Benefits, Ensuring You
                and Your Family are Carefully Protected.
              </p>
            </div>
          </Fade>
          <Fade bottom>
            <div className="fourCardStyle cardFixedHeight">
              <img
                style={{ alignSelf: "center", maxWidth: "60%" }}
                src={tb}
                alt="speed"
              />
              <h4 className="subsCardHeading">
                3 weeks vacations and days off !
              </h4>
              <p>
                Time to Recharge and Reconnect: Enjoy Valuable Moments of Rest
                and Recreation with Generous Vacation and Leave Allocations,
                Nurturing a Positive Work-Life Harmony.
              </p>
            </div>
          </Fade>
        </div>
        <Fade bottom>
          <Row
            style={{
              marginTop: 100,
            }}
          >
            <Col sm={5}>
              <div
                className="CustomCard"
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Fade left>
                  <div
                    style={{
                      maxWidth: "500px",
                    }}
                  >
                    <img
                      src={frontEnd}
                      alt="speed"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        display: "block",
                      }}
                    />
                  </div>
                </Fade>
              </div>
            </Col>
            <Col sm={7}>
              <div className="CustomCard me">
                <Fade bottom>
                  <div
                    className="fourCardStyleNoBorder cardStyles"
                    style={{
                      width: "100%",
                      textAlign: "left",
                    }}
                  >
                    <h1 className="subsCardHeading">Marketing Intern</h1>
                    <hr />
                    <p
                      className="bodyTxt"
                      style={{
                        maxWidth: "800px",
                      }}
                    >
                      Are you eager to acquire hands-on experience in the
                      dynamic field of marketing? We cordially invite you to
                      embark on an exciting journey as a Marketing Intern at our
                      esteemed organization. If you possess proficiency or
                      familiarity with any of the technologies mentioned below,
                      we eagerly anticipate the opportunity to learn more about
                      your qualifications.
                      <br />
                      If you have experience and knowledge in any of the
                      following technologies, we’d love to hear from you!
                    </p>
                    <div>
                      <h4>Your Role:</h4>
                      <hr />
                      <div className="mmListWrapper">
                        <ListGroup variant="flush">
                          {renderListItems(roleContent)}
                        </ListGroup>
                      </div>
                      <br />
                      <h4>What We Offer:</h4>
                      <hr />
                      <div className="mmListWrapper">
                        <ListGroup variant="flush">
                          {renderListItems(offerContent)}
                        </ListGroup>
                      </div>

                      <br />
                      <h4>Who we are looking for:</h4>
                      <hr />
                      <div className="mmListWrapper">
                        <ListGroup variant="flush">
                          {renderListItems(lookingForContent)}
                        </ListGroup>
                      </div>
                      {/* Add your Call to Action button here */}
                    </div>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Marketting intern application</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <EmployerForm onChange={handleChildPropChange} />
                        <p className="asktikText">
                          Disclaimer : Please note that this internship
                          opportunity is unpaid. This internship program is
                          designed to provide valuable learning experiences,
                          hands-on training, and exposure to the field of
                          marketing. It offers a platform for you to apply your
                          skills and knowledge in a professional setting while
                          contributing to our projects. By submitting your
                          application, you acknowledge that this internship
                          position is not compensated monetarily but aims to
                          offer valuable educational and skill-building
                          opportunities.
                        </p>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                    <Button
                      variant="light"
                      className="ctaButton"
                      onClick={() => setShow(true)}
                    >
                      Apply Now
                    </Button>
                  </div>
                </Fade>
              </div>
            </Col>
          </Row>
        </Fade>
      </div>
    </div>
  );
}
